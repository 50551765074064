import React, { useEffect, useState } from 'react';
import "./CustInputRange.scss";


const CustInputRangeBodyWeight = (props) => {
  const { setInputVal, inputVal } = props;
  const { Tab, setTab } = props;

  const updateRangeDisplay = (stepsVal) => {
    props.ErrMsg("");
    if (Tab == 'Imperial') {
      tabVal(stepsVal, 396);

    };
    if (Tab == 'Metric') {
      tabVal(stepsVal, 180)
    };
  }

  const tabVal = (value, tbVal) => {
    let val = value;
    if (isNaN(val) === true) {
      setInputVal('');
    } else {
      if (val.includes('.')) {
        let d = (val + "").split(".")[1];
        if (d === "") {
          if (value > tbVal) {
            setInputVal(tbVal)
          } else {
            setInputVal(val)
          }
        } else {
          if ((`${d}`).length > 1) {

          } else {
            if (value > tbVal) {
              setInputVal(tbVal)
            } else {
              setInputVal(val)
            }
          }
        }
      } else {
        if (value > tbVal) {
          setInputVal(tbVal)
        } else {
          setInputVal(val)
        }
      }
    }
  }

  return (
    <div className="form-group row customSlider">
      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minVal} </span>
          <span className="tick">{props.maxVal} </span>
        </div>
        <input type="range" style={{ width: '100%' }} value={(inputVal) ? inputVal : props.minVal} step={.1} min={props.minVal} name="hghtrng" max={props.maxVal} defaultValue={props.minVal} onChange={(event) => updateRangeDisplay(event.target.value)} />
      </div>
      <div className='col-12'>
        <div className='d-flex justify-content-between'>
          <label className='form-check-label' style={{ fontSize: 15, color: '#383030eb' }}>Measured Body Weight<span style={{ fontSize: 13 }}>{"  " + props.Label}</span></label>
          <input type="text" style={{ width: '100px', height: '38px' }} value={(inputVal == "0.0") ? "" : inputVal} name="inputVal" onChange={(event) => updateRangeDisplay(event.target.value)} id="input_val" />
        </div>
      </div>

    </div>
  )
}

export default CustInputRangeBodyWeight;