import React, { useEffect, useState } from 'react';
import BodyAnt from "./bodyMap/bodyAnt";
// import BodyPost from "./bodyMap/bodyPost";
import { CircleSlider } from "react-circle-slider";
import style from "./bodySVG.module.css";
import PageHeader from '../../../components/PageHeader/PageHeader';
import CustInputRange from '../../../components/common/CustInputRange/CustInputRange';
import CustInputRangeBodyWeight from '../../../components/common/CustInputRange/CustInputRangeBodyWeight';
//import { BodyComponent } from "reactjs-human-body";
import { BodyComponent } from "@darshanpatel2608/human-body-react";



function SegmentalCalc() {
  const [bodyState, setBodyState] = useState({
    head: {
      clickable: false
    },
    chest: {
      clickable: false
    },
    stomach: {
      clickable: false
    },
    left_shoulder: {
      value: 2.7,
      selected: false
    },
    right_shoulder: {
      value: 2.7,
      selected: false

    },
    left_arm: {
      value: 1.6,
      selected: false
    },
    right_arm: {
      value: 1.6,
      selected: false

    },
    left_leg: {
      value: 16,
      selected: false
    },
    right_leg: {
      value: 16,
      selected: false
    },
    left_hand: {
      value: 0.7,
      selected: false
    },
    right_hand: {
      value: 0.7,
      selected: false
    },
    left_foot: {
      value: 1.5,
      selected: false
    },
    right_foot: {
      value: 1.5,
      selected: false
    },
    left_leg_upper: {
      value: 10.1,
      selected: false
    },
    right_leg_upper: {
      value: 10.1,
      selected: false,
    },
    left_leg_lower: {
      value: 4.4,
      selected: false,
    },
    right_leg_lower: {
      value: 4.4,
      selected: false
    },
  });

  const [state, setState] = useState([]);
  const [minVal, setMinValue] = useState(77);
  const [ErrMsg, setErrMsg] = useState("")
  const [maxVal, setMaxValue] = useState(396);
  const [value, setValue] = useState(5);
  const [name, setName] = useState("");
  const [p, setP] = useState(0);
  const [estimated, setEstimated] = useState(0);
  const [mode, setMode] = useState("selection");
  const [inputVal, setInputVal] = useState("");
  const [Tab, setTab] = useState('Imperial');
  const [HiIn, setHiIn] = useState('(lb)');
  const [sliderValue, setSliderValue] = useState(65);

  useEffect(
    () => {
      if (localStorage.getItem("setUser") != null) {
        var Dflt;
        let DefltSystm = JSON.parse(localStorage.getItem("setUser"));
        Dflt = DefltSystm?.userInfo?.country_view_id;
        if (Dflt == 2) {
          document.getElementById('chkPrv').checked = true;
          setTab('Metric');
          setHiIn(" (kg)");
          setInputVal(prev => (prev / 2.2).toFixed(1))
          setMinValue(35);
          setMaxValue(180);
        } else {
          document.getElementById('chkPrv').checked = false;
          setTab('Imperial');
          setHiIn(" (lb)");
          setInputVal(prev => (prev * 2.2).toFixed(1));
          setMinValue(77);
          setMaxValue(396);
        }
      }
    }, []
  );

  const handleClickBody = (newValue) => {
    if (inputVal < minVal) {
      setErrMsg("Please enter value above" + " " + minVal + ".");
    } else {
      let p = 0;
      const values = Object.values(newValue);
      values.filter((item) => item.selected === true).map((item) => {
        p = p + item.value;
      })
      setP(p);
    }
  };

  useEffect(() => {
    setSliderValue((inputVal * 100) / (100 - p));
  }, [inputVal, p])

  // const onChangeWeight = (value) => {
  //   setSliderValue(value);
  // }

  const HandleChange = (e) => {
    if (e.target && e.target.value) {
      if (e.target.name == "chkPrvCheck") {
        if (e.target.checked == true) {
          setTab('Metric');
          setHiIn("(kg)");
          setInputVal(prev => (prev / 2.2).toFixed(1))
          setMinValue(35);
          setMaxValue(180);
        } else {
          setTab('Imperial');
          setHiIn("(lb)");
          setInputVal(prev => (prev * 2.2).toFixed(1));
          setMinValue(77);
          setMaxValue(396);
        }
      }
    }
  }
  return (
    <div className='container-fluid px-0'>
      <div className="row">
        <div className='col-12 px-0'>
          <PageHeader style={{ marginTop: '30px' }}
            bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
            title="Segmental Weights Calculator"
            description={"Estimate body weight when evaluating the nutritional status of patients with amputations"} />

          <section className="section novi-background bg-cover " >
            <div className='container py-5'>
              <div className='row card-bs'>
                <div className='col-12 d-lg-none px-5'>
                  <div className=''>
                    <div className="custom-control mb-5 mt-lg-5 pt-lg-5">
                      <h6 className='d-inline-block pr-2' value="Metric" name="tab1">Imperial</h6>
                      <h6 className='d-inline-block custom-switch'>
                        <input type="checkbox" className="custom-control-input" name="chkPrvCheck" id="chkPrv" onChange={HandleChange} />
                        <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                      </h6>
                      <h6 className='d-inline-block' value="Imperial" name="tab">Metric</h6>
                    </div>

                    <CustInputRangeBodyWeight Label={HiIn} Tab={Tab} setTab={setTab} ErrMsg={setErrMsg} inputVal={(inputVal)} setInputVal={setInputVal} minVal={minVal} maxVal={maxVal} />
                  </div>
                </div>
                <div className='col-lg-6 col-12 p-5' style={{ background: "#a6c550", height: '630px' }}>
                  <h3 style={{ marginTop: '-35px' }}>
                    <span className="badge badge-pill bg-white" data-toggle="tooltip" data-placement="right" title="">
                      {p > 0 ? <i className="fa fa-solid">{-p.toFixed(1)}%</i> : <i className="fa fa-solid fa-question"></i>}
                    </span>
                  </h3>
                  <BodyComponent partsInput={bodyState} mode="missing" onChange={(test) => handleClickBody(test)} />
                </div>
                <div className='col-lg-6 col-12 pt-lg-5 pt-2 px-5 text-center'>
                  <h5 className='d-lg-none mb-4' style={{ color: 'rgb(43, 125, 159)', fontSize: '26px' }}> {""} Select amputated limbs</h5>
                  <div className='d-none d-lg-block'>
                    <div className="custom-control mb-5 mt-lg-5 pt-lg-5">
                      <h6 className='d-inline-block pr-2' value="Metric" name="tab1">Imperial</h6>
                      <h6 className='d-inline-block custom-switch'>
                        <input type="checkbox" className="custom-control-input" name="chkPrvCheck" id="chkPrv" onChange={HandleChange} />
                        <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                      </h6>
                      <h6 className='d-inline-block' value="Imperial" name="tab">Metric</h6>
                    </div>

                    <CustInputRangeBodyWeight Label={HiIn} Tab={Tab} setTab={setTab} ErrMsg={setErrMsg} inputVal={(inputVal)} setInputVal={setInputVal} minVal={minVal} maxVal={maxVal} />
                  </div>

                  <h5 className='d-none d-lg-block'> <span><i className="fa fa-arrow-left" style={{ color: '#2B7D9F' }}>{""} Select amputated limbs</i></span></h5>


                  <div className='pt-lg-5'>
                    <h3>  Estimated Body Weight</h3>
                    <h5><span className='badge badge-pill mx-auto sliderValue mb-3 text-white' style={{ background: "#a6c550", borderRadius: 0 }}> {sliderValue.toFixed(1)} {Tab == "Imperial" ? "lb" : "kg"} </span></h5>
                    <h3><span className='badge badge-pill mx-auto sliderValue mb-3 text-white' style={{ background: "#a6c550", borderRadius: 0 }}></span></h3>
                  </div>
                  <div style={{ color: 'red' }}> {ErrMsg} </div>

                </div>

              </div>
              <div className='mt-4 px-3' style={{ color: '#484343' }}><b>Reference:</b>  <span><a target="_blank" href=" https://pubmed.ncbi.nlm.nih.gov/7852688/">Osterkamp LK. Current perspective on assessment of human body proportions of relevance to amputees. J Am Diet Assoc 1995;95:215-218.</a></span></div>
            </div>

          </section>
        </div>
      </div>
    </div>
  )
}

export default SegmentalCalc