import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader';

const BodySurfaceArea = () => {

    const [System, setSystem] = useState('Imp');
    const [Height, setHeight] = useState();
    const [Weight, setWeight] = useState();
    const [BSA, setBSA] = useState();
    const [MinValHeigt, setMinValHeigt] = useState(39);
    const [MinValWeigt, setminValWeigt] = useState(77);
    const [MaxValHeigt, setMaxValHeigt] = useState(83);
    const [MaxValWeigt, setMaxValWeigt] = useState(396);
    const [cHeight, csetHeight] = useState();
    const [cWeight, csetWeight] = useState();
    const [nHeight, nsetHeight] = useState();
    const [nWeight, nsetWeight] = useState();
    const [SysHght, setSysHght] = useState(" (in)");
    const [SysWght, setSysWght] = useState(" (lb)");
    const [ErrMsg, setErrMsg] = useState("");
    const [WeightRange, setWeightRange] = useState("");
    const [HeightRange, setHeightRange] = useState("");

    useEffect(
        () => {
            let DefaultSystem = "";
            if (localStorage.getItem('setUser') != null) {
                DefaultSystem = JSON.parse(localStorage.getItem('setUser'));
            }
            if (DefaultSystem?.userInfo?.country_view_id == 2) {
                document.getElementById("chkPrv").checked = true;
                setSysHght(" (cm)");
                setSysWght(" (kg)");
                setSystem("Met");
                setMinValHeigt(100);
                setminValWeigt(35);
                setMaxValWeigt(180);
                setMaxValHeigt(210);
            } else {
                document.getElementById("chkPrv").checked = false;
                setSysHght(" (in)");
                setSysWght(" (lb)");
                setSystem("Imp");
                setMinValHeigt(39);
                setminValWeigt(77);
                setMaxValWeigt(396);
                setMaxValHeigt(83);
            }
        }, []
    )

    const TabChange = (e) => {
        setErrMsg("");
        setBSA("");
        if (e.target.name == "ChkImpMet") {
            let CnvrtdWeight = "";
            let CnvrtdHeight = "";
            if (e.target.checked == true) {
                csetHeight();
                csetWeight();
                if ((Height == undefined || Height == "") && (Weight == undefined || Weight == "")) {

                } else if ((Height != undefined && Weight == undefined) || (Height != "" && Weight == "")) {
                    csetHeight(Height);
                    if (nHeight != undefined && nHeight != "") {
                        CnvrtdHeight = nHeight;
                        setHeight(CnvrtdHeight);
                        setHeightRange(CnvrtdHeight);
                    } else {
                        CnvrtdHeight = Height * 2.54;
                        if (CnvrtdHeight > 210) {
                            setHeight(210);
                            setHeightRange(210);
                        } else {
                            setHeight(CnvrtdHeight.toFixed(1));
                            setHeightRange(CnvrtdHeight.toFixed(1));
                        }
                    }
                } else if ((Height == undefined && Weight != undefined) || (Height == "" && Weight != "")) {
                    csetWeight(Weight);
                    if (nWeight != "" && nWeight != undefined) {
                        CnvrtdWeight = nWeight;
                        setWeight(CnvrtdWeight);
                        setWeightRange(CnvrtdWeight);
                    } else {
                        CnvrtdWeight = Weight / 2.20462;
                        if (CnvrtdWeight > 180) {
                            setWeight(180);
                            setWeightRange(180);
                        } else {
                            setWeight(CnvrtdWeight.toFixed(1));
                            setWeightRange(CnvrtdWeight.toFixed(1));
                        }
                    }
                } else {
                    csetWeight(Weight);
                    csetHeight(Height);
                    if ((nHeight == "" && nWeight == "") || (nHeight == undefined && nWeight == undefined)) {
                        CnvrtdWeight = Weight / 2.20462;
                        CnvrtdHeight = Height * 2.54;
                        if (CnvrtdWeight > 180) {
                            setWeight(180);
                            setWeightRange(180);
                        } else {
                            setWeight(CnvrtdWeight.toFixed(1));
                            setWeightRange(CnvrtdWeight.toFixed(1));
                        }
                        if (CnvrtdHeight > 210) {
                            setHeight(210);
                            setHeightRange(210);
                        } else {
                            setHeight(CnvrtdHeight.toFixed(1));
                            setHeightRange(CnvrtdHeight.toFixed(1));
                        }
                    } else {
                        CnvrtdWeight = nWeight;
                        CnvrtdHeight = nHeight;
                        setWeight(CnvrtdWeight);
                        setHeight(CnvrtdHeight);
                        setWeightRange(CnvrtdWeight);
                        setHeightRange(CnvrtdHeight);
                    }
                }
                setSysHght(" (cm)");
                setSysWght(" (kg)");
                setSystem("Met");
                setMinValHeigt(100);
                setminValWeigt(35);
                setMaxValWeigt(180);
                setMaxValHeigt(210);
            } else {
                nsetHeight();
                nsetWeight();
                if (((Height == undefined || Height == "") && (Weight == undefined || Weight == "")) || ((Height == "" || Height == undefined) && (Weight == "" || Weight == undefined))) {

                } else if ((Height != undefined && Weight == undefined) || (Height != "" && Weight == "")) {
                    nsetHeight(Height);
                    if (cHeight != undefined && cHeight != "") {
                        CnvrtdHeight = cHeight;
                        setHeight(CnvrtdHeight);
                        setHeightRange(CnvrtdHeight);
                    } else {
                        CnvrtdHeight = Height / 2.54;
                        if (CnvrtdHeight > 83) {
                            setHeight(83);
                            setHeightRange(83);
                        } else {
                            setHeight(CnvrtdHeight.toFixed(1));
                            setHeightRange(CnvrtdHeight.toFixed(1));
                        }
                    }
                } else if ((Height == undefined && Weight != undefined) || (Height == "" && Weight != "")) {
                    nsetWeight(Weight);
                    if (cWeight != undefined && cWeight != "") {
                        CnvrtdWeight = cWeight;
                        setWeight(CnvrtdWeight);
                        setWeightRange(CnvrtdWeight);
                    } else {
                        CnvrtdWeight = Weight * 2.20462;
                        if (CnvrtdWeight > 396) {
                            setWeight(396);
                            setWeightRange(396);
                        } else {
                            setWeight(CnvrtdWeight.toFixed(1));
                            setWeightRange(CnvrtdWeight.toFixed(1));
                        }
                    }
                } else {
                    nsetWeight(Weight);
                    nsetHeight(Height);
                    if ((cHeight == "" && cWeight == "") || (cHeight == undefined && cWeight == undefined)) {
                        CnvrtdWeight = Weight * 2.20462;;
                        CnvrtdHeight = Height / 2.54;
                        if (CnvrtdWeight > 396) {
                            setWeight(396);
                            setWeightRange(396);
                        } else {
                            setWeight(CnvrtdWeight.toFixed(1));
                            setWeightRange(CnvrtdWeight.toFixed(1));
                        }
                        if (CnvrtdHeight > 83) {
                            setHeight(83);
                            setHeightRange(83);
                        } else {
                            setHeight(CnvrtdHeight.toFixed(1));
                            setHeightRange(CnvrtdHeight.toFixed(1));
                        }
                    } else {
                        CnvrtdWeight = cWeight;
                        CnvrtdHeight = cHeight;
                        setWeight(CnvrtdWeight);
                        setHeight(CnvrtdHeight);
                        setWeightRange(CnvrtdWeight);
                        setHeightRange(CnvrtdHeight);
                    }

                }
                setSysHght(" (in)");
                setSysWght(" (lb)");
                setSystem("Imp");
                setMinValHeigt(39);
                setminValWeigt(77);
                setMaxValWeigt(396);
                setMaxValHeigt(83);
            }
        }
    }

    const ChangeHandle = (e) => {
        csetHeight("");
        csetWeight("");
        nsetHeight("");
        nsetWeight("");
        setBSA("");
        setErrMsg("");
        if (e.target.name == "heightval" || e.target.name == "heightrange") {
            if (isNaN(e.target.value) === true) {
                setErrMsg("Only Digits are Allowed");
                setHeight("");
            } else if (e.target.value == '0') {
                setErrMsg("Please Enter Greater Then  or Equal " + MinValHeigt + ".");
                setHeight("");
            } else if (System == "Imp" && e.target.value > 83) {
                setErrMsg("Invalid Height. *");
            } else if (System == "Met" && e.target.value > 210) {
                setErrMsg("Invalid Height. *");
            } else if (e.target.value.includes('.') == true) {
                let Dup = (e.target.value + "").split(".")[1];
                if (Dup === "") {
                    setHeight(e.target.value);
                    setHeightRange(e.target.value);
                } else {
                    if ((`${Dup}`).length > 1) {
                        //================
                    } else {
                        setHeight(e.target.value)
                        setHeightRange(e.target.value);
                    }
                }
            } else {
                e?.length <= 3
                setHeight(e.target.value);
                setHeightRange(e.target.value);
            }
        } else if (e.target.name == "weightval" || e.target.name == "weightrange") {
            if (isNaN(e.target.value) === true) {
                setErrMsg("Only Digits are Allowed");
                setWeight("");
            } else if (e.target.value == '0') {
                setErrMsg("Please Enter Greater Then or Equal " + MinValWeigt + ".");
                setWeight("");
            } else if (System == "Imp" && e.target.value > 396) {
                setErrMsg("Invalid Weight. *");
            } else if (System == "Met" && e.target.value > 180) {
                setErrMsg("Invalid Weight. *");
            } else if (e.target.value.includes('.') == true) {
                let Dup = (e.target.value + "").split(".")[1];
                if (Dup === "") {
                    setWeight(e.target.value);
                    setWeightRange(e.target.value);
                } else {
                    if ((`${Dup}`).length > 1) {
                        //================
                    } else {
                        setWeight(e.target.value)
                        setWeightRange(e.target.value);
                    }
                }
            } else {
                e?.length <= 3
                setWeight(e.target.value);
                setWeightRange(e.target.value);
            }
        }
    }

    const CalBSA = () => {
        let BSArea = "";
        let ClcultedVlue = "";
        if ((Weight == "" || Weight == undefined) && (Height == "" || Height == undefined)) {
            setErrMsg("Enter Your Weight and Height. **");
        } else if (Weight == "" || Weight == undefined) {
            setErrMsg("Enter Your Weight. *");
        } else if (Height == "" || Height == undefined) {
            setErrMsg("Enter Your Height. *");
        } else if (System === "Imp") {
            if (Weight < 77 && Height < 39) {
                setErrMsg("Invalid Height and Weight. **");
            } else if (Height < 39) {
                setErrMsg("Invalid Height. *");
            } else if (Weight < 77) {
                setErrMsg("Invalid Weight. *");
            } else {
                ClcultedVlue = Math.sqrt(Height * Weight / 3131);
                BSArea = ClcultedVlue.toFixed(2);
                setErrMsg("");
            }
        } else if (System === "Met") {
            if (Weight < 35) {
                setErrMsg("Invalid Weight. *");
            } else if (Height < 100) {
                setErrMsg("Invalid Height. *");
            } else {
                ClcultedVlue = Math.sqrt(Height * Weight / 3600);
                BSArea = ClcultedVlue.toFixed(2);
                setErrMsg("");
            }
        } else {
            setErrMsg("No Calculation");
        }
        if (BSArea == undefined || BSArea == "") {
            setBSA(0);
        } else if (isNaN(BSArea) == true) {
            setBSA(0);
        }
        else {
            setBSA(BSArea);
        }
    }
    // useEffect(() => {

    //     const meta = document.createElement('meta');
    //     meta.name = 'viewport';
    //     meta.content = 'width=device-width, initial-scale=0.5';
    //     const existingMeta = document.querySelector('meta[name="viewport"]');
    //     if (existingMeta) {
    //         existingMeta.remove();
    //     }


    //     document.head.appendChild(meta);


    //     return () => {
    //         meta.remove();
    //     };
    // }, []);
    return (
        <>
            <div>

                <PageHeader
                    bgimg="https://img.freepik.com/free-photo/adult-child-holding-kidney-shaped-paper-textured-blue-background-world-kidney-day-national-organ-donor-day-charity-donation-concept_49149-1211.jpg"
                    title="Body&nbsp;Surface&nbsp;Area&nbsp;(BSA)&nbsp;Calculator"
                    description={"Estimate body surface area (widely used in oncology practice) based on measured height and weight"} />

                <section className="section section-50 section-lg-95 novi-background bg-cover">
                    <div className='container'>
                        <div className="row offset-top-40 card-bs" style={{ marginTop: '-50px' }}>
                            <div className='col-lg-6 text-left p-5 d-flex flex-column justify-content-between' style={{ marginTop: '-50px' }}>
                                <div className="input-group-append px-3">
                                    <div className="input-group-prepend" style={{ marginTop: '20px', marginLeft: '-12px' }}>
                                        <h6 className='d-inline-block pr-2' value="Imperial" name="tab">Imperial</h6>
                                        <h6 className='d-inline-block custom-switch'>
                                            <input type="checkbox" className="custom-control-input" name="ChkImpMet" onChange={TabChange} id="chkPrv" />
                                            <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                                        </h6>
                                        <h6 className='d-inline-block' value="Metric" name="tab">Metric</h6>
                                    </div>
                                </div>
                                <br />
                                <div style={{ marginTop: '-15px' }}>
                                    <div className="form-group row customSlider">
                                        <div className='col-sl-12 input-group' style={{ marginTop: '10px' }}>
                                            <div style={{ marginTop: '-7px', width: '100%' }} className='d-flex justify-content-between'>
                                                <span style={{ fontSize: '12px' }}>{MinValHeigt}</span>
                                                <span style={{ fontSize: '12px' }}>{MaxValHeigt}</span>
                                            </div>

                                            <input style={{ width: '100%' }} step={.1} id="heightrange" name='heightrange' type="range" onChange={ChangeHandle} value={(HeightRange) ? HeightRange : MinValHeigt} min={MinValHeigt} max={MaxValHeigt} defaultValue={MinValHeigt} />
                                        </div>
                                        <div className='col-7'>
                                            <label className="form-check-label" style={{ marginTop: '6px', fontSize: '15px', color: '#383030eb' }}>Height<span style={{ color: 'gray', fontSize: '13px' }}>{SysHght}</span></label>
                                        </div>
                                        <div className='col-5 text-right'>
                                            <input type="text"
                                                style={{ width: '80px', height: '35px' }} name="heightval" min={MinValHeigt} max={MaxValHeigt} value={Height} id='htval' onChange={ChangeHandle} />
                                        </div>
                                    </div>
                                    <div className="form-group row customSlider">
                                        <div className='input-group' style={{ marginTop: '30px' }}>
                                            <div style={{ marginTop: '-7px', width: '100%' }} className='d-flex justify-content-between'>
                                                <span style={{ fontSize: '12px' }}>{MinValWeigt}</span>
                                                <span style={{ fontSize: '12px' }}>{MaxValWeigt}</span>
                                            </div>
                                            <input style={{ width: '100%' }} step={.1} id="weightrange" name='weightrange' type="range" onChange={ChangeHandle} value={(WeightRange) ? WeightRange : MinValWeigt} min={MinValWeigt} max={MaxValWeigt} defaultValue={MinValWeigt} />
                                            <div className='col-7'>
                                                <label className="form-check-label" style={{ marginTop: '6px', fontSize: '15px', color: '#383030eb' }}>Weight<span style={{ color: 'gray', fontSize: '13px' }}>{SysWght}</span></label>
                                            </div>
                                            <div className='col-5 text-right'>
                                                <input
                                                    type="text" style={{ width: '80px', height: '35px' }} name="weightval" min={MinValWeigt} max={MaxValWeigt} value={Weight} id='wtval' onChange={ChangeHandle} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='form-group row' style={{ marginTop: '35px' }}>
                                    <div className='col-12 text-lg-left text-center'>
                                        <div style={{ color: 'red' }}> {ErrMsg} </div>
                                        <button className='btn btn-primary' onClick={CalBSA}>Calculate</button>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 p-5 d-flex align-items-center' style={{ background: "#a6c550" }}>
                                <div className="col">
                                    <h2 className='text-white mb-3 display-4'>BSA</h2>
                                    <div className='badge bg-white py-2 px-3'><h5 className='text-primary'>{BSA == "" ? 0 : BSA} m<sup>2</sup></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div style={{ marginTop: '-41px', color: '#484343' }}><b>Reference: </b> <a target='_blank' href="https://pubmed.ncbi.nlm.nih.gov/3657876">Mosteller RD. Simplified calculation of body-surface area. N Engl J Med 1987;317(17) : 1098.</a></div>
            </div>
        </>
    )
}

export default BodySurfaceArea;